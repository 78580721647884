div.landing-card {
    display: grid;
    grid-template: 'descrip animi' 100% / minmax(300px, 1fr) minmax(300px, 1fr);
    align-items: center;

    // height: 100%;
    padding: 4rem 10vw;

    color: var(--landing-card-text-color);

    background: var(--landing-card-background);

    @media screen and (max-width: 500px) {
        grid-template: 'descrip' 100% / 1fr;
    }

    > .description {
        grid-area: descrip;

        max-width: 410px;

        > .descrip-1 {
            font-size: 2.625rem;

            > span {
                color: var(--navbar-action-background-color);
            }
        }

        > .descrip-2 {
            margin: 1rem 0 2rem;

            font-size: 1.063rem;
            font-family: 'Euclid CircularA Light', 'Open Sans', sans-serif;
            line-height: 1.5rem;
        }

        & .action-link {
            padding: 0.625rem;

            color: var(--navbar-action-text-color);
            font-size: 1.125rem;
            text-decoration: none;

            border: none;
            border-radius: 0.313rem;

            background-color: var(--navbar-action-background-color);

            &:hover {
                background-color: var(--navbar-action-background-color-hover);
            }
        }
    }

    > .description-animation {
        grid-area: animi;

        @media screen and (max-width: 600px) {
            display: none;
        }

        > .main-img {
            height: 80vh;
        }
    }
}
