[class$='-button'] {
    text-decoration: none;

    > div {
        float: left;

        width: 9.75rem;
        height: 2.938rem;
        margin: 0.1vw 2vw 1vw 0;

        font-size: 1.125rem;
        font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
        line-height: 2.938rem;
        text-align: center;

        border-radius: 5px;
    }

    &.blue-button {
        > div {
            color: var(--white);

            border: 1px solid var(--products-button-border-color);

            background-color: var(--products-button-background);
        }
    }

    &.white-button {
        > div {
            color: var(--button-text-color);

            border: 1px solid var(--products-button-border-color);

            background-color: var(--button-color);
        }
    }

    &.blue-border-button {
        > div {
            color: var(--button-text-color);

            border: 1px solid var(--products-button-border-color);

            background-color: var(--button-color);
        }
    }
}
