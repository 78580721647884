div.achievement {
    display: grid;
    grid-template-rows: 18% 82%;

    min-height: 100%;
    padding: 4rem 10vw;

    background-color: var(--achievements-background);

    .header {
        position: relative;

        height: min-content;
        margin-block: auto;

        font-size: 2.25rem;
        font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;

        &::before {
            position: absolute;
            top: -1.5rem;
            content: 'Our achievement';
            z-index: 1;

            font-size: 1.125rem;
            font-family: 'Euclid CircularA Light', 'Open Sans', sans-serif;
        }
    }

    > .grid-wrapper {
        display: grid;
        grid-template:
            'animi yrs' 15%
            'animi achieved' 80% / minmax(350px, 1fr) minmax(250px, 3fr);

        max-height: 60vh;
        padding: 3vw 1.5vw;

        border-radius: 20px;

        background-color: var(--achievements-acieved-background);
        @media screen and (max-width: 1000px) {
            grid-template:
                'yrs' min-content
                'animi' 2vh
                'achieved' / 1fr;

            max-height: 80vh;
            padding: 6vw;

            overflow: scroll;
        }

        > .yrs {
            grid-area: yrs;

            color: var(--achievements-acieved-heading-text-color);
            font-size: 1.5rem;

            background-color: var(--achievements-acieved-background);

            > span {
                font-size: 1.813rem;
                font-family: 'Euclid CircularA SemiBold', 'Open Sans', sans-serif;
            }
        }

        > .animi {
            grid-area: animi;

            margin-block: auto;
            @media screen and (max-width: 1000px) {
                display: none;
            }

            > div {
                > #lottie {
                    > svg[preserveAspectRatio='xMidYMid meet'] {
                        width: 200% !important;

                        transform: translate3d(-100px, -50px, 0) !important;
                    }
                }
            }
        }

        > .achieved {
            grid-area: achieved;

            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 0.1rem;

            color: var(--achievements-acieved-heading-text-color);

            background-color: var(--achievements-border-background);
            @media screen and (max-width: 1000px) {
                grid-template-columns: repeat(2, 1fr);
            }

            > div {
                display: grid;
                place-items: center;

                padding: 1vw;

                background-color: var(--achievements-acieved-background);
                @media screen and (max-width: 1000px) {
                    text-align: center;
                }

                > div {
                    width: 80%;
                    height: 80%;

                    > div {
                        padding: 1vw 0;

                        color: var(--achievements-acieved-text-color);
                        font-size: 0.938rem;
                        font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
                    }

                    > span {
                        font-size: 2rem;
                        font-family: 'Euclid CircularA SemiBold', 'Open Sans', sans-serif;
                    }
                }
            }
        }
    }
}
