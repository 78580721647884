img.arrow {
    position: absolute;

    height: 3rem;

    &.right {
        right: 25px;
    }

    &.left {
        left: 25px;

        transform: rotate(180deg);
    }
}
