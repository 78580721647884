div.psgapplication-overview {
    display: grid;

    min-height: 125%;

    > .grid-wrapper {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 2vw;
        align-content: center;

        min-height: calc(125% / 4);
        padding: 2vw 10vw;
        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
        }

        &:nth-child(2) {
            background-color: var(--thadupoosi-overview-background-color);
        }

        > .heading {
            margin-block: 10px;

            font-size: 1.5rem;
            font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
        }

        > .para {
            margin-block: 0;

            font-size: 0.875rem;
            font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
            line-height: 2em;
            text-align: justify;
        }
    }
}
