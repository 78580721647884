div.footer {
    display: flex;
    flex-wrap: wrap;

    // gap: 100px;

    height: 2.5rem;
    padding: 0 10vw;

    font-size: 0.875rem;

    border-top: 0.5px solid var(--footer-border-color);

    > ul {
        display: flex;
        flex: 1 1 1px;
        gap: 6vw;

        padding: 1vw;

        list-style: none;

        > li {
            margin: auto;
        }
    }

    > div {
        display: flex;

        > a {
            > img {
                width: 50px;
                height: 50px;
                margin: 15px;
            }
        }
    }
}
