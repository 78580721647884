div.econnect-description {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 3rem;
    place-content: center;

    // min-height: 100%;
    padding: 4vw 10vw;

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    > .heading {
        font-size: 1.5rem;
        font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
    }

    > .para {
        > .img {
            display: flex;
            gap: 2vw;
            align-items: center;

            margin-bottom: 1rem;
        }

        > .descrip {
            font-size: 0.875rem;
            font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
            line-height: 2rem;
            text-align: justify;
        }
    }
}
