div.psgapplication-img {
    display: grid;
    grid-auto-flow: column;

    height: 100%;

    // padding: 7vw 0;

    > .grid-wrapper {
        display: grid;
        grid-auto-flow: column;
        gap: 1.563rem;
        place-content: center;
        align-items: center;

        height: 90%;
        margin: auto 0;
        padding: 0 10vw;

        background-color: var(--thadupoosi-img-background-color);

        > .psg-image {
            width: 40em;
            height: 30em;

            border-radius: 5px;

            object-fit: contain;
        }
    }
}
