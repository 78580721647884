div.job-description {
    display: grid;

    width: 100%;
    padding: 50px 10vw;

    color: var(--job-description-text-color);

    > * {
        width: 100%;
        height: auto;
    }

    h1,
    h2,
    p {
        margin-block: 0;
    }

    > .jd-banner {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        margin-bottom: 40px;
        padding: 5vh 5vw;

        border-radius: 18px;

        background-color: var(--job-description-banner-color);

        > .banner {
            > .arrow-icon {
                display: flex;
                gap: 10px;
                align-items: center;

                cursor: pointer;
            }

            > .arrow-icon h3 {
                color: var(--job-description-banner-text-color);
            }

            > .arrow-icon h3:hover {
                color: var(--job-description-banner-text-color);

                cursor: pointer;
            }

            > h1 {
                color: var(--job-description-banner-text-color);
                font-size: 2rem;
                font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
            }

            > .employment-details {
                display: flex;
                flex-wrap: wrap;
                gap: 25px;
                align-items: center;

                margin: 15px 0;

                color: var(--job-description-banner-text-color);
                font-size: 1rem;

                > * {
                    display: flex;

                    column-gap: 10px;
                }

                img {
                    filter: brightness(0);
                }

                > .experience {
                    > img {
                        width: 20px;
                        height: 20px;
                    }
                }

                > .job-city {
                    font-size: 1rem;

                    border-radius: 50px;

                    > img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        > button.apply-btn {
            margin: auto 0 auto auto;
            padding: 15px 30px;

            color: var(--job-description-apply-btn-text-color);
            font-size: 1rem;
            font-family: 'Euclid Circular Regular', 'Open Sans', sans-serif;

            border: none;
            border-radius: 10px;

            background-color: var(--job-description-apply-btn-bg-color);

            cursor: pointer;
        }
    }

    > .description {
        padding-top: 40px;

        font-size: 1.2rem;
        line-height: 1.5em;

        border-top: 1px solid var(--job-description-border-color);
    }

    > .responsibilities {
        > h2 {
            margin: 20px 0;

            font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
        }

        > ul {
            padding-inline-start: 1.1rem;

            font-size: 1.2rem;
            line-height: 1.8rem;
        }
    }

    > .qualifications {
        > h2 {
            margin: 20px 0;

            font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
        }

        > ul {
            padding-inline-start: 1.1rem;

            font-size: 1.2rem;
            line-height: 1.8rem;
        }
    }

    > form {
        font-size: 1rem;

        input {
            display: block;

            margin: 5px 0 10px;
            padding: 10px 5px;

            border: 2px solid var(--job-description-form-input-border-color);
            border-radius: 10px;
        }

        .select-container {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
        }

        .country-name {
            display: block;

            width: 25%;
            margin: 5px 0 10px;
            padding: 10px 5px;

            border: 2px solid var(--job-description-form-input-border-color);
            border-radius: 10px;

            background-color: white;
        }

        .country-code,
        .country-flag,
        .country-number {
            display: block;

            margin: 5px 0 10px;
            padding: 10px 5px;

            border: 2px solid var(--job-description-form-input-border-color);
            border-radius: 10px;

            background-color: white;
        }

        .phonenumber {
            margin: 5px 0 10px;
        }

        .phonenumber-input,
        .country-code {
            border: 2px solid var(--job-description-form-input-border-color);
            border-radius: 10px;
        }

        input[type='file'] {
            padding: 0;

            border: none;
        }

        input::file-selector-button {
            padding: 10px 49px;

            color: grey;
            font-weight: bold;

            border: 2px solid var(--job-description-form-input-border-color);
            border-radius: 10px;

            background-color: white;
            background-image: url('../../assets/images/jobDescriptionPage/uploadicon.svg');
            background-repeat: no-repeat;
            background-position-x: 15%;
            background-position-y: 40%;
            background-size: 13%;
        }

        > h2.form-heading {
            margin: 20px 0;

            color: var(--job-description-form-heading-color);
            font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
        }

        > .name-fields {
            display: flex;
            flex-wrap: wrap;

            column-gap: 15px;
        }

        > .work-links {
            > h3.work-links-heading {
                margin-bottom: 5px;
            }
        }

        > sub {
            color: #696969;
            vertical-align: 35%;
        }

        > button.submit-btn {
            margin: 15px 0;
            padding: 15px;

            color: var(--job-description-apply-btn-text-color);
            font-size: 1rem;
            font-family: 'Euclid Circular Regular', 'Open Sans', sans-serif;

            border: none;
            border-radius: 10px;

            background-color: var(--job-description-apply-btn-bg-color);

            cursor: pointer;
        }
    }
}
