div.psgapplication-card {
    display: grid;

    height: 100%;
    padding: 10vw 0;

    @media screen and (max-width: 600px) {
        height: auto;
    }

    > .arrow-icon {
        display: flex;
        gap: 10px;
        align-items: center;

        margin-left: 10vw;

        cursor: pointer;

        filter: brightness(0);
    }

    > .arrow-icon h3 {
        color: var(--products-name-text-color);
    }

    > .arrow-icon h3:hover {
        color: var(--products-name-text-color);

        cursor: pointer;
    }

    > .grid-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        place-content: center;

        height: 100%;
        padding: 0 10vw;

        background-color: var(--services-side-menu-background);

        // background-image: url('../../assets/images/products/gymapplication/background.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;

        @media screen and (max-width: 600px) {
            height: 100%;
            margin: auto;
        }

        > .descrip {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            place-content: center;
            justify-items: center;
            align-items: center;

            padding: 2vw;
            @media screen and (max-width: 600px) {
                grid-template-columns: repeat(1, 1fr);
            }

            > p {
                color: white;
                font-weight: bold;
                font-size: 45px;
                font-family: 'Euclid Circular Regular', 'Open Sans', sans-serif;
            }

            > img {
                width: 20rem;
                height: 20rem;
                margin-top: 50px;

                border-radius: 5px;

                object-fit: contain;

                @media screen and (max-width: 1110px) {
                    display: none;
                }
            }

            > .header {
                margin-bottom: 0.625rem;

                font-size: 3rem;
                font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
            }

            > .sub-header {
                font-size: 1.375rem;
                font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
            }

            > ul {
                padding-inline-start: 0;

                list-style: none;

                > li {
                    padding: 0.313rem 0;

                    font-size: 1.25rem;
                    font-family: 'Euclid CircularA Regular', 'Open Sans', sans-serif;
                }
            }
        }

        > .img {
            margin: auto;
        }
    }
}
