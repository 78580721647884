body.light {
    --background-color: #ffffff;

    /* Navbar */
    --navbar-background: #99ebff;
    --navbar-action-background-color: #00c8fa;
    --navbar-action-background-color-hover: #06bae7;
    --navbar-action-text-color: #ffffff;
    --navbar-item-background: #00000000;
    --navbar-item-background-hover: #c8c8c834;
    --navbar-item-text-color: #000000;

    /* Landing Card */
    --landing-card-background: transparent linear-gradient(180deg, #99ebff 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    --landing-card-text-color: #000000;

    // Services
    --services-border-shadow: #0000000d;
    --services-border-color: #00c8fa59;
    --services-side-menu-background: #00c8fa;
    --services-side-menu-active-background: #ffffff;
    --services-side-menu-active-text-color: #00c8fa;
    --services-content-border-color: #808080;
    --services-content-background: #ffffff;

    // Expertise
    --expertise-menu-background: #f3f3f3;

    // Achievements
    --achievements-background: #f3f3f3;
    --achievements-acieved-background: #ffffff;
    --achievements-border-background: #00c8fa;
    --achievements-acieved-text-color: #555555;
    --achievements-acieved-heading-text-color: #000000;

    // Products
    --products-background: #ffffff;
    --products-button-background: #1dd7f3;
    --products-button-border-color: #1dd7f3;
    --products-name-text-color: #000000;

    // caseStudies
    --case-studies-background: #f3f3f3;

    // client Card
    --client-feedback-background: #ffffff;
    --client-feedback-text-color: #000000;
    --client-card-background: #f3f3f3;
    --client-card-shadow: #0000001a;
    --client-card-border-color: #a7c9f5;

    // Contacts
    --contacts-contact-text-color: #000000;
    --contacts-form-text-color: #000000;
    --contacts-form-background: #fafafa;
    --contacts-form-border-color: #0000001a;
    --contacts-input-background: #f3f3f3;
    --contacts-button-background: #00c8fa;
    --contacts-link-color: #1dd7f3;

    // Career
    --career-button-background: #1dd7f3;
    --career-name-text-color: #000000;
    --career-background: #ffffff;
    --career-text-color: #000000;

    // Footer
    --footer-border-color: #747474;

    // ThadupoosiCard
    --thadupoosi-card-background-color: #ecfcff;

    // Thadupoosi Overview
    --thadupoosi-overview-background-color: #fafafa;

    // Thadupoosi Image
    --thadupoosi-img-background-color: #ecfcff;

    // Thadupoosi KnowMore
    --thadupoosi-knowmore-card-background-color: #f5f5f5;

    // Job Description
    --job-description-banner-color: #99ebff;
    --job-description-banner-text-color: black;
    --job-description-border-color: #d2d2d2;
    --job-description-text-color: #000000;
    --job-description-apply-btn-text-color: #ffffff;
    --job-description-apply-btn-bg-color: #00c8fa;
    --job-description-form-input-border-color: #348ce3;
    --job-description-form-heading-color: #f16924;

    // career
    --career-text-color: #000000;
    --career-border-color: #d2d2d2;
    --career-card-border-color: #d9d9d9;
    --career-job-sector-text-color: #ffffff;
    --career-job-sector-bg-color: #00c8fa;
    --career-banner-text-color: #ffffff;
    --career-job-city-bg-color: #f3f3f3;
    --career-join-btn-bg-color: #292d32;

    // Button Component
    --button-text-color: #1dd7f3;
    --button-color: #ffffff;
}

body.dark {
    --background-color: #15022d;

    /* Navbar */
    --navbar-background: #15022d;
    --navbar-action-background-color: #00c8fa;
    --navbar-action-background-color-hover: #06bae7;
    --navbar-action-text-color: #ffffff;
    --navbar-item-background: #00000000;
    --navbar-item-background-hover: #c8c8c834;
    --navbar-item-text-color: #ffffff;

    /* Landing Card */
    --landing-card-background: #15022d;
    --landing-card-text-color: #ffffff;

    // Services
    --services-border-shadow: #0000000d;
    --services-border-color: #00c8fa59;
    --services-side-menu-background: #00c8fa;
    --services-side-menu-active-background: #191d29;
    --services-side-menu-active-text-color: #ffffff;
    --services-content-border-color: #808080;
    --services-content-background: #191d29;

    // Expertise
    --expertise-menu-background: #191d29;

    // Achievements
    --achievements-background: #15022d;
    --achievements-acieved-background: #191d29;
    --achievements-border-background: #00c8fa;
    --achievements-acieved-text-color: #ffffff;
    --achievements-acieved-heading-text-color: #00c8fa;

    // Products
    --products-background: #15022d;
    --products-button-background: #1dd7f3;
    --products-button-border-color: #1dd7f3;
    --products-name-text-color: #ffffff;

    // caseStudies
    --case-studies-background: #15022d;

    // client Card
    --client-feedback-background: #ffffff;
    --client-feedback-text-color: #000000;
    --client-card-background: #15022d;
    --client-card-shadow: #0000001a;
    --client-card-border-color: #a7c9f5;

    // Button Component
    --button-text-color: #ffffff;
    --button-color: #15022d;

    // Contacts
    --contacts-contact-text-color: #ffffff;
    --contacts-form-text-color: #000000;
    --contacts-form-background: #fafafa;
    --contacts-form-border-color: #0000001a;
    --contacts-input-background: #f3f3f3;
    --contacts-button-background: #00c8fa;
    --contacts-link-color: #1dd7f3;

    // Career
    --career-button-background: #1dd7f3;
    --career-name-text-color: #ffffff;
    --career-background: #15022d;
    --career-text-color: #ffffff;

    // Footer
    --footer-border-color: #747474;

    // ThadupoosiCard
    --thadupoosi-card-background-color: #191d29;

    // Thadupoosi Overview
    --thadupoosi-overview-background-color: #191d29;

    // Thadupoosi Image
    --thadupoosi-img-background-color: #191d29;

    // Thadupoosi KnowMore
    --thadupoosi-knowmore-card-background-color: #191d29;

    // Job Description
    --job-description-banner-color: #99ebff;
    --job-description-banner-text-color: #000000;
    --job-description-border-color: #d2d2d2;
    --job-description-text-color: #ffffff;
    --job-description-apply-btn-text-color: #ffffff;
    --job-description-apply-btn-bg-color: #00c8fa;
    --job-description-apply-btn-disabled-bg-color: #dddddd;
    --job-description-form-input-border-color: #348ce3;
    --job-description-form-heading-color: #f16924;

    // career
    --career-text-color: #ffffff;
    --career-border-color: #d2d2d2;
    --career-card-border-color: #d9d9d9;
    --career-job-sector-text-color: #ffffff;
    --career-job-sector-bg-color: #00c8fa;
    --career-banner-text-color: #ffffff;
    --career-job-city-bg-color: #191d29;
    --career-join-btn-bg-color: #292d32;

    color: #ffffff;
}

:root {
    --black: #000000;
    --white: #ffffff;
}
