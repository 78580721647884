nav {
    position: absolute;
    z-index: 9999;

    display: flex;
    align-items: center;

    width: 100%;
    padding: 20px 50px;

    background-color: var(--navbar-background);

    overflow-x: auto;

    @media screen and (max-width: 1150px) {
        flex-direction: column;

        padding: 10px 20px;
    }

    .active {
        @media screen and (max-width: 1150px) {
            display: none;
            align-items: center;
        }
    }

    > div {
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: 1150px) {
            justify-content: space-between;
            align-items: center;

            width: 100%;
        }

        &:last-child {
            flex: 1 1 1px;

            @media screen and (max-width: 1150px) {
                flex-direction: column;
                align-items: flex-end;
            }
        }

        > button {
            width: 50px;
            aspect-ratio: 1;

            font-size: 2.5rem;

            cursor: pointer;
            all: unset;
            @media screen and (min-width: 1150px) {
                display: none;
            }
        }

        > img.logo {
            position: relative;
            top: -9px;

            height: 40px;
        }

        > a {
            margin-right: 10px;
            padding: 10px 18px;

            color: var(--navbar-item-text-color);
            text-align: center;
            text-decoration: none;

            border-radius: 8px;

            background-color: var(--navbar-item-background);

            cursor: pointer;

            &:hover {
                background-color: var(--navbar-item-background-hover);
            }

            &.action-link {
                color: var(--navbar-action-text-color);

                background-color: var(--navbar-action-background-color);

                &:hover {
                    background-color: var(--navbar-action-background-color-hover);
                }
            }
        }
    }
}
