div.career {
    display: grid;

    width: 100%;
    padding: 50px 10vw;

    color: var(--career-text-color);

    > * {
        width: 100%;
        height: auto;
    }

    h1,
    h3,
    p {
        margin-block: 0;
    }

    > .arrow-icon {
        display: flex;
        gap: 10px;

        cursor: pointer;
    }

    > .arrow-icon h3:hover {
        cursor: pointer;
    }

    > .caption {
        height: fit-content;

        border-bottom: 1px solid var(--career-border-color);

        > h1 {
            margin-block: 0;

            font-size: 3.25rem;
            font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
        }

        > p {
            margin: 15px 0;

            font-size: 1.6rem;
            font-family: 'Euclid Circular Regular', 'Open Sans', sans-serif;
        }
    }

    > .jobs-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(clamp(250px, 50vw, 400px), 1fr));

        padding: 40px 0;

        border-bottom: 1px solid var(--career-border-color);

        > .job-category {
            > h3.category-heading {
                margin-top: 0%;

                font-size: 1.813rem;
                font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
            }

            > p {
                margin: 10px 0;

                font-size: 1.438rem;
            }
        }

        > .job-positions {
            display: grid;
            row-gap: 25px;

            > a {
                color: var(--career-text-color);
                text-decoration: none;

                > .position-card {
                    padding: 20px;

                    border: 1px solid var(--career-card-border-color);
                    border-radius: 23px;

                    > .job-title-container {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;
                        justify-content: space-between;
                        align-items: center;

                        > .job-role {
                            margin-right: 10px;
                            padding: 5px 0;

                            font-size: 1.625rem;
                            font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
                        }

                        > .job-sector {
                            padding: 5px 10px;

                            color: var(--career-job-sector-text-color);
                            font-size: 0.813rem;

                            border-radius: 50px;

                            background-color: var(--career-job-sector-bg-color);
                        }
                    }

                    > p {
                        margin: 7px 0 15px;

                        font-size: 1.1rem;
                    }

                    > .employment-details {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 25px;
                        align-items: center;

                        font-size: 1rem;

                        > * {
                            display: flex;

                            column-gap: 10px;
                        }

                        > .experience {
                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }

                        > .job-city {
                            font-size: 1rem;

                            border-radius: 50px;

                            > img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    > .career-banner {
        margin: 60px 0 0;
        padding: 10vh 10vw;

        color: var(--career-banner-text-color);

        border-radius: 18px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        > h1 {
            margin: 15px 0;

            font-size: 2rem;
            font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
        }

        > p.banner-para {
            margin: 15px 0;

            font-size: 1.5rem;
            font-family: 'Euclid Circular Regular', 'Open Sans', sans-serif;
        }

        > a {
            > button.join-btn {
                margin: 15px 0;
                padding: 15px 20px;

                color: var(--career-banner-text-color);
                font-size: 1.2rem;
                font-family: 'Euclid Circular Regular', 'Open Sans', sans-serif;
                text-decoration: none;

                border: none;
                border-radius: 12px;

                background-color: var(--career-join-btn-bg-color);
            }
        }
    }
}
