div.services {
    display: grid;
    grid-template-rows: auto;

    padding: 4rem 10vw;

    .header {
        position: relative;

        height: min-content;
        padding-bottom: 25px;

        font-size: 2.25rem;
        font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;

        &::before {
            position: absolute;
            top: -1.5rem;
            content: 'We offer';
            z-index: 1;

            font-size: 1.125rem;
            font-family: 'Euclid CircularA Light', 'Open Sans', sans-serif;
        }
    }

    .service-container {
        display: grid;
        grid-template-columns: minmax(75px, 1fr) minmax(225px, 3fr);

        height: 38em;

        // border: 1px solid var(--services-border-color);
        border-radius: 7px 0 0 7px;

        box-shadow: 0 3px 3px var(--services-border-shadow);

        @media screen and (min-width: 300px) and (max-width: 330px) {
            height: 85em;
        }

        @media screen and(min-width:350px) and (max-width: 500px) {
            height: 85em;
        }

        @media screen and (min-width: 340px) and (max-width: 349px) {
            height: 75em;
        }

        @media screen and (min-width: 701px) and (max-width: 800px) {
            height: 45em;
        }

        @media screen and (min-width: 601px) and (max-width: 700px) {
            height: 50em;
        }

        @media screen and (min-width: 501px) and (max-width: 600px) {
            height: 56em;
        }

        @media screen and (min-width: 801px) and (max-width: 900px) {
            height: 45em;
        }

        @media (orientation: landscape) and (max-width: 600px) {
            height: 200vh;
        }

        @media (orientation: landscape) and (max-width: 900px) {
            height: 50em;
        }

        @media (orientation: landscape) and (max-width: 425px) and (max-height: 320px) {
            height: 76em;
        }

        > .side-menu {
            position: relative;

            display: flex;
            flex-direction: column;
            place-content: space-around center;

            width: 100%;

            color: var(--white);
            font-size: 1.375rem;

            border-radius: 7px 0 0 7px;

            background-color: var(--services-side-menu-background);

            @media screen and (max-width: 1200px) {
                font-size: 1.2rem;
            }

            @media screen and (max-width: 600px) {
                justify-items: center;
            }

            > .first-element {
                &::before {
                    display: none;
                }

                span {
                    position: absolute;
                    bottom: 50%;
                }
            }

            > .last-element {
                &::after {
                    display: none;
                }

                span {
                    position: absolute;
                    top: 45%;
                }
            }

            > .active {
                position: absolute;
                top: 0;
                z-index: 1;

                display: grid;
                place-content: center;

                width: calc(100%);

                background-color: var(--services-side-menu-active-background);

                transition: 0.5s ease;

                &::after {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    content: '';
                    z-index: 9999;

                    width: 100%;
                    height: 15px;

                    color: var(--services-side-menu-background);

                    border-radius: 0 15px 0 0;

                    background-color: var(--services-side-menu-background);
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    z-index: 9999;

                    width: 100%;
                    height: 15px;

                    color: var(--services-side-menu-background);

                    border-radius: 0 0 15px;

                    background-color: var(--services-side-menu-background);
                }

                > span {
                    display: inline-block;

                    width: calc(100%);

                    color: var(--services-side-menu-background);
                    line-height: 53px;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    overflow: hidden;

                    @media screen and (max-width: 1030px) {
                        // display: none;
                    }
                }

                > img {
                    @media screen and (min-width: 1030px) {
                        display: none;
                    }
                }
            }

            > .menu {
                position: inherit;
                z-index: 2;

                display: flex;
                justify-content: center;

                // flex-direction: column;
                align-items: center;

                height: 16.6%;

                cursor: pointer;

                > span {
                    display: inline-block;

                    width: calc(100%);
                    padding: 0 10px;

                    font-size: 1.45vw;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    overflow: hidden;
                    @media screen and (max-width: 1030px) {
                        display: none;
                    }
                }

                > img {
                    width: 50px;
                    height: 50px;

                    filter: brightness(0) invert(1);

                    object-fit: contain;
                    @media screen and (min-width: 1030px) {
                        display: none;
                    }
                }

                &.menu-active {
                    color: var(--services-side-menu-active-text-color);

                    & > img {
                        filter: brightness(1) invert(0);
                    }
                }
            }
        }

        > .content {
            height: auto;
            padding: 2rem;

            background-color: var(--services-content-background);

            transition: all 500ms ease;

            > .menu-content {
                display: none;

                height: auto;

                background-color: var(--services-content-background);

                transition: all 500ms ease;

                > .img {
                    width: 30px;
                    height: 30px;

                    object-fit: contain;
                    @media screen and (max-width: 1030px) {
                        display: none;
                    }
                }

                > .menu-heading {
                    font-family: 'Euclid CircularA SemiBold', 'Open Sans', sans-serif;

                    @media screen and (min-width: 1030px) {
                        display: none;
                    }
                }

                > div {
                    margin: 1vw 0;

                    > .icons {
                        display: flex;
                        gap: 1vw;

                        > .container {
                            > .img {
                                width: 2rem;

                                &:hover {
                                    cursor: pointer;

                                    filter: invert(100%) sepia(31%) saturate(6951%) hue-rotate(150deg) brightness(100%) contrast(103%);
                                }
                            }

                            > .active {
                                filter: invert(100%) sepia(31%) saturate(6951%) hue-rotate(150deg) brightness(100%) contrast(103%);
                            }

                            > .bottom-ref-active {
                                width: 1em;
                                height: 4px;
                                margin: auto;

                                border: 1px solid #e3813a;
                                border-radius: 5px;

                                background-color: #e3813a;
                            }

                            > .bottom-ref {
                                display: none;
                            }
                        }
                    }

                    > h2 {
                        position: relative;

                        font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;

                        &::after {
                            position: absolute;
                            top: 2.3rem;
                            left: 0;
                            bottom: -5;
                            content: '';
                            z-index: 1;

                            width: 2.875rem;

                            border-bottom: 0.5px solid var(--services-content-border-color);
                        }
                    }

                    > .blue-content {
                        display: flex;
                        gap: 1vw;

                        color: var(--services-side-menu-active-text-color);
                        font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;

                        > .per {
                            font-size: 2.25rem;
                        }

                        > .per-content {
                            padding: 5px;

                            font-size: 0.875rem;
                        }

                        > div {
                            > .up-arrow {
                                float: left;

                                width: 1.5vw;
                                @media screen and (max-width: 1030px) {
                                    width: 1.5vh;
                                }
                            }
                        }
                    }
                }

                &.active {
                    display: block;
                }
            }
        }
    }
}
