div.expertise {
    display: flex;

    // grid-template-rows: 18% 13% 77%;
    flex-direction: column;

    // height: 80vh;
    padding: 2rem 10vw;

    // @media (orientation: landscape) {
    //     display: grid;
    //     grid-template-rows: 18% 13% 77%;

    //     height: max-content;
    //     padding: 2rem 10vw;
    // }

    .header {
        position: relative;

        height: max-content;
        padding-bottom: 25px;

        font-size: 2.25rem;
        font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;

        &::before {
            position: absolute;
            top: -1.5rem;
            content: 'Our Industry';
            z-index: 1;

            font-size: 1.125rem;
            font-family: 'Euclid CircularA Light', 'Open Sans', sans-serif;
        }
    }

    > .top-menu {
        position: relative;

        display: flex;
        flex-wrap: wrap;

        height: 50px;

        font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;

        border-radius: 5px;

        background-color: var(--expertise-menu-background);

        .marker {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;

            width: 0;

            border-bottom: 0.188rem solid;

            transition: all 500ms ease;
        }

        > div {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 23%;
            height: inherit;
            margin: 0 1%;

            cursor: pointer;

            > img {
                height: 50%;
            }

            > span {
                padding: 2vh;
                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
        }
    }

    > .grid-wrapper {
        display: grid;
        grid-template-columns: minmax(200px, 1fr) minmax(320px, 1fr);

        padding-inline: 5vw;
        @media screen and (max-width: 600px) {
            grid-template-columns: minmax(200px, 1fr);
        }

        > .para {
            display: grid;
            align-items: center;

            font-size: 1.125rem;
            text-align: justify;

            > div {
                > div {
                    margin-bottom: 3vh;

                    > h2 {
                        font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
                        @media screen and (min-width: 600px) {
                            display: none;
                        }
                    }

                    > span {
                        margin-bottom: 3vh;

                        font-size: 1.75rem;
                    }
                }
            }

            > .hidden {
                display: none;
            }
        }

        > .expertise-animi {
            min-width: 380px;
            margin: auto;
            padding: 0 5vw;
            @media screen and (max-width: 600px) {
                display: none;
            }
        }
    }
}
