@import url('./Fonts.scss');
@import url('./Theme.scss');

html,
body {
    margin: 0;
    padding: 0;

    font-family: 'Euclid Circular Regular', 'Open Sans', sans-serif;

    background-color: var(--background-color);

    overflow-x: hidden;
}

#root {
    display: flex;
    flex-direction: column;

    height: 100vh;

    white-space: pre-wrap;

    overflow: hidden;

    > div.scroll-container {
        flex: 1 1 1px;

        margin-top: 50px;

        overflow-x: hidden;

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar {
            width: 0.5vw;

            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-image: linear-gradient(
                180deg,
                rgb(153 235 255 / 100%) 0%,
                rgb(153 235 255 / 100%) 25%,
                transparent 100%,
                rgb(153 235 255 / 100%) 75%,
                transparent
            );
        }
    }
}

* {
    box-sizing: border-box;
    scroll-behavior: smooth;

    // Remove Arrows/Spinners

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        margin: 0;

        appearance: none;
    }

    /* Firefox */
    input[type='number'] {
        appearance: textfield;
    }
}

img {
    max-width: 100%;
}

// Screen container
.full-vh {
    max-width: 100%;
}

button[disabled],
button:disabled {
    background-color: var(--job-description-apply-btn-disabled-bg-color);
}
