div.insuranceapplication-knowmore {
    display: grid;

    // min-height: 100%;
    padding: 4vw 10vw;

    text-align: center;
    @media screen and (max-width: 600px) {
        grid-template-rows: auto;
    }

    > .heading {
        padding: 1rem;

        font-size: 2.25rem;
        font-family: 'Euclid CircularA SemiBold', 'Open Sans', sans-serif;
    }

    > p {
        margin-block: 0;
        padding: 1rem;

        font-size: 1.125rem;
        font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
    }

    > .carousel {
        margin: 0.8rem 10vw;

        > ul {
            > li {
                > div {
                    width: 98%;
                    margin: auto;
                    padding: 1.25rem;

                    border-radius: 10px;

                    background-color: var(--thadupoosi-knowmore-card-background-color);

                    > img {
                        height: 150px;
                    }
                }
            }
        }

        > img.arrow.right {
            right: 0;
        }

        > img.arrow.left {
            left: 0;
        }
    }
}
