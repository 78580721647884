div.career {
    display: grid;

    padding: 4rem 10vw;

    background-color: var(--career-background);

    > .header {
        height: min-content;
        margin-block: auto;
        margin-bottom: 3vw;

        font-size: 2.25rem;
        font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
    }

    > .grid-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(clamp(250px, 50vw, 470px), 1fr));
        grid-gap: 40px;

        > .img {
            > img {
                max-height: 460px;
            }
        }

        > .content {
            > .career-content {
                margin-bottom: 1vw;

                color: var(--career-name-text-color);
                font-size: 2.188rem;
                font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
            }

            > .about {
                margin-bottom: 2vw;

                font-size: 1.5rem;
                font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;

                > p {
                    margin: 1vh 0 3vh;

                    color: var(--career-text-color);
                    font-size: 1rem;
                    font-family: 'Euclid Circular Regular', 'Open Sans', sans-serif;
                    line-height: 1.3rem;
                    text-align: justify;
                }
            }
        }
    }
}
