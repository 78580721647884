div.contacts {
    position: relative;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(clamp(250px, 50vw, 400px), 1fr));

    padding: 4rem 10vw;

    > .contacts {
        display: grid;

        margin-inline: 4vw;
        padding: 2rem;

        @media screen and (max-width: 600px) {
            margin-inline: -2vw;
        }

        > .header {
            position: relative;

            font-size: 2.25rem;
            font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;

            &::before {
                position: absolute;
                top: -1.5rem;
                content: 'Lets';
                z-index: 1;

                font-size: 1.125rem;
                font-family: 'Euclid CircularA Light', 'Open Sans', sans-serif;
            }
        }

        > span {
            margin-top: 1rem;

            color: var(--contacts-contact-text-color);
            font-size: 1.125rem;
            font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
        }

        > div {
            > a {
                color: var(--contacts-link-color);
                text-decoration: none;

                > img {
                    width: min(12vw, 4rem);
                    padding: 0.5rem;
                }
            }
        }
    }

    > .form {
        display: grid;

        margin-inline: 4vw;
        padding: 2rem;

        color: var(--contacts-form-text-color);
        font-size: 1.125rem;

        border-radius: 5px;

        background-color: var(--contacts-form-background);

        box-shadow: 0 3px 3px var(--contacts-form-border-color);

        > .select-container {
            display: flex;
            gap: 10px;
        }

        .country-name,
        .country-flag,
        .country-code,
        .country-number {
            gap: 20px;

            width: 100%;
            margin: 0.5rem 0;
            padding: 0.8rem 0.5rem;

            font-size: 1rem;

            border: none;
            border-radius: 5px;

            background-color: var(--contacts-input-background);

            resize: none;
        }

        > input,
        textarea,
        .phonenumber {
            width: 100%;
            margin: 0.5rem 0;
            padding: 0.8rem 0.5rem;

            font-size: 1rem;

            border: none;
            border-radius: 5px;

            background-color: var(--contacts-input-background);

            resize: none;

            > .phonenumber-input {
                width: 100%;
                height: 15px;

                border: none;

                background-color: var(--contacts-input-background);
            }

            .flag-dropdown {
                border: none;
            }

            .invalid-number-message {
                color: black;
            }
        }

        > input {
            max-height: 3.125rem;
        }

        > button {
            height: 3.125rem;

            color: var(--white);
            font-size: 1.125rem;

            border: 1px solid var(--contacts-button-background);
            border-radius: 5px;

            background-color: var(--contacts-button-background);

            cursor: pointer;
        }

        > * {
            margin-top: 1rem;
        }
    }
}
