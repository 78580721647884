div.product {
    display: grid;

    padding: 4rem 10vw;

    background-color: var(--products-background);

    > .header {
        height: min-content;
        margin-block: auto;
        margin-bottom: 3vw;

        font-size: 2.25rem;
        font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
    }

    > .grid-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(clamp(250px, 50vw, 470px), 1fr));

        > .carousel {
            > ul {
                > li {
                    > .internal-wrapper {
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(clamp(250px, 50vw, 470px), 1fr));

                        margin-left: 20px;
                        padding: 20px 0;

                        > .img {
                            margin: auto;

                            > img {
                                width: 350px;
                                height: 240px;
                                max-height: 460px;

                                border-radius: 15px;

                                // &.vertical {
                                //     width: 350px;
                                //     height: 240px;

                                //     object-fit: contain;
                                // }
                            }
                        }

                        > .content {
                            margin: auto;

                            > .product-name {
                                margin-bottom: 1vw;

                                color: var(--products-name-text-color);
                                font-size: 2.188rem;
                                font-family: 'Euclid CircularA Medium', 'Open Sans', sans-serif;
                            }

                            > .about {
                                margin-bottom: 2vw;

                                font-size: 1.5rem;
                                font-family: 'Euclid Circular Medium', 'Open Sans', sans-serif;

                                > p {
                                    margin: 1vh 0 3vh;

                                    font-size: 1rem;
                                    font-family: 'Euclid Circular Regular', 'Open Sans', sans-serif;
                                    text-align: justify;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
