@font-face {
    font-family: 'Euclid Circular Regular';
    src: url('./assets/fonts/EuclidCircularRegular.ttf') format('truetype');
}
@font-face {
    font-family: 'Euclid CircularA Light';
    src: url('./assets/fonts/EuclidCircularA-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Euclid CircularA Medium';
    src: url('./assets/fonts/EuclidCircularA-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Euclid CircularA SemiBold';
    src: url('./assets/fonts/EuclidCirculaA-SemiBold.ttf') format('truetype');
}
